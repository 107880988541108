import React from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Container,
  alpha,
  useTheme,
  TextField,
  MenuItem,
} from "@mui/material";
import country from "country-list-js";

const HomeLeadership = () => {
  const theme = useTheme();
  const countries = country.ls("name");
  return (
    <Box>
      <Container sx={{ py: 5 }}>
        <Box>
          <Typography variant="h4" textAlign={"center"}>
            Leadership Sacerdotal de l'apôtre LMK
          </Typography>
          <Typography
            sx={{ px: { xs: 3, md: 30 }, my: 2 }}
            color={"textSecondary"}
            textAlign={"center"}
          >
            Un engagement spirituel profond et un leadership guidé par la foi en
            Jesus-Christ. Son leadership guide et inspire plusieurs personnes
            dans le monde. Il attire et honore, ses fils font la fierté du
            Royaume partout ils exercent le ministère.
          </Typography>
        </Box>
        <Grid sx={{ my: 3 }} container spacing={2}>
          <Grid
            container
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            sx={{
              p: 3,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `linear-gradient(
        to bottom,
         ${alpha(theme.palette.primary.main, 0.3)},
         ${alpha(theme.palette.primary.main, 0.4)}
      ), url('/images/mwasi.JPG')`,
              height: "auto",
              borderRadius: 6,
            }}
            item
            xs={12}
            md={8}
          >
            <Box
              sx={{
                backgroundColor: "white",
                p: 5,
                width: "40%",
                borderRadius: 6,
              }}
            >
              <Typography fontSize={10} sx={{ my: 2 }} color={"textSecondary"}>
                SACERDOTAL
              </Typography>
              <Typography sx={{ fontSize: { xs: 15, md: 22 } }} variant="h4">
                Ecole des femmes
              </Typography>
              <Typography sx={{ fontSize: { xs: 13, md: 19 } }} variant="h5">
                "Bizaleli ya mwasi"
              </Typography>
              <Typography sx={{ my: 1, fontSize: { xs: 10, md: 12 } }}>
                Dans toute sa complexité, l’École « Bizaleli ya Mwasi » qui peut
                se traduire par « Le comportement » ( = éducation, caractère,
                attitude, réflexe…de la femme), est une autre vision de Dieu,
                révélée à son serviteur pour ramener la femme à sa phase
                initiale selon Dieu, tout en épinglant ses droits et ses
                devoirs.
              </Typography>
              <Typography sx={{ my: 2 }}>
                <Button
                  component="a"
                  href="/ecole-des-femmes"
                  sx={{ fontSize: { xs: 10, md: 13 } }}
                  variant="contained"
                >
                  En savoir plus
                </Button>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                borderWidth: 1,
                borderStyle: "double",
                borderColor: "#eee",
                borderRadius: 5,
                p: 5,
              }}
            >
              <Typography fontSize={10} sx={{ my: 2 }} color={"textSecondary"}>
                INVITATION
              </Typography>
              <Typography variant="h5">Inviter l'Apôtre</Typography>
              <form>
                <Grid sx={{ my: 2 }} container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Nom complet"
                      name="name"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Poste"
                      name="post"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="E-mail"
                      name="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Téléphone"
                      name="phoneNumber"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Pays"
                      name="country"
                      select
                    >
                      {countries.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Résumé"
                      name="details"
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </form>
              <Typography sx={{ my: 2 }}>
                <Button fullWidth variant="contained">
                  S'abonner
                </Button>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                borderWidth: 1,
                borderStyle: "double",
                borderColor: "#eee",
                borderRadius: 5,
                p: 5,
              }}
            >
              <Typography>
                <img src="/images/emi-home.jpg" width={"100%"} />
              </Typography>
              <Typography variant="h5">
                Ecole du Ministère Internationale
              </Typography>
              <Typography
                color={"text.secondary"}
                sx={{ my: 1, fontSize: { xs: 13, md: 16 } }}
              >
                La grande École du Ministère Internationale, EMI en sigle, est
                un cadre qui permet à Dieu de préparer, d'encadrer, d'équiper,
                de former, de modeler ceux qu'il prépare à envoyer sur le champ
                de bataille.
              </Typography>
              <Typography sx={{ my: 2 }}>
                <Button component="a" href="/emi" variant="contained">
                  En savoir plus
                </Button>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                borderWidth: 1,
                borderStyle: "double",
                borderColor: "#eee",
                borderRadius: 5,
                p: 5,
              }}
            >
              <Typography>
                <img src="/images/autels.jpg" width={"100%"} />
              </Typography>
              <Typography variant="h5">Destruction des autels</Typography>
              <Typography color={"text.secondary"} sx={{ my: 1 }}>
                La Destruction des Autels est une grande rélélation que le
                Seigneur Jésus-Christ a accordé à son serviteur l'Apôtre Léopold
                MUTOMBO. Dans ce programme, Dieu a plongé son peuple dans une
                sorte de traitement prolongé pendant une période.
              </Typography>
              <Typography sx={{ my: 2 }}>
                <Button
                  component="a"
                  href="/destruction-des-autels"
                  variant="contained"
                >
                  En savoir plus
                </Button>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                borderWidth: 1,
                borderStyle: "double",
                borderColor: "#eee",
                borderRadius: 5,
                p: 5,
              }}
            >
              <Typography>
                <img src="/images/emi.jpg" width={"100%"} />
              </Typography>
              <Typography variant="h5">Business Class</Typography>
              <Typography color={"text.secondary"} sx={{ my: 1 }}>
                Ce message révolutionne réellement la mentalité du chrétien et
                met à sa disposition les clés, les stratégies dégageant une
                onction exceptionnelle pour le faire basculer de la pauvreté à
                richesse, des échecs aux réussites, de succès à la domination
                dans son domaine spécifique.
              </Typography>
              <Typography sx={{ my: 2 }}>
                <Button
                  component="a"
                  href="/conference-business-class"
                  variant="contained"
                >
                  En savoir plus
                </Button>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HomeLeadership;
