import About from "./pages/about";
import Agenda from "./pages/agenda";
import Business from "./pages/business";
import Destruction from "./pages/destruction";
import Emi from "./pages/emi";
import Home from "./pages/home";
import Jeunes from "./pages/jeunes";
import Missions from "./pages/missions";
import Mwasi from "./pages/mwasi";
import Oeuvres from "./pages/oeuvres";
import BlogDetails from "./pages/oeuvres/Details";
import BecomePartner from "./pages/partner";
import QuinzaineProphetique from "./pages/quinzaine";
import Shop from "./pages/shop";
import ShopDetails from "./pages/shop/Details";

const routes = [
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/a-propos",
    component: <About />,
  },
  {
    path: "/emi",
    component: <Emi />,
  },
  {
    path: "/ecole-des-femmes",
    component: <Mwasi />,
  },
  {
    path: "/conference-business-class",
    component: <Business />,
  },
  {
    path: "/departement-des-jeunes",
    component: <Jeunes />,
  },
  {
    path: "/destruction-des-autels",
    component: <Destruction />,
  },
  {
    path: "/quinzaine-et-semaine-prophetique",
    component: <QuinzaineProphetique />,
  },
  {
    path: "/shop",
    component: <Shop />,
  },
  {
    path: "/shop/:ID",
    component: <ShopDetails />,
  },
  {
    path: "/agenda",
    component: <Agenda />,
  },
  {
    path: "/oeuvres",
    component: <Oeuvres />,
  },
  {
    path: "/blog/:articleID",
    component: <BlogDetails />,
  },
  {
    path: "/missions",
    component: <Missions />,
  },
  {
    path: "/devenir-partenaire",
    component: <BecomePartner />,
  },
];

export default routes;
