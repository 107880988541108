import React from "react";
import Breadcrumb from "../../components/includes/Breadcrumb";
import { Container, Grid, Typography } from "@mui/material";
import OurShop from "../../components/includes/Shop";

const Jeunes = () => {
  return (
    <div>
      <Breadcrumb title={"Récollection des jeunes"} />
      <Container sx={{ my: 5 }}>
        <Grid sx={{ textAlign: "justify" }} container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              La Réco-Jeune est un centre d’encadrement des jeunes de tous les
              horizons. Elle est une rencontre évangélique, scientifique,
              économique et socio-culturelle afin d’outiller et de rendre utile
              la jeunesse pour Dieu et pour la société.
            </Typography>
            <Typography sx={{ my: 2 }}>
              La « Réco-Jeune » constitue un moment de prédilection où les
              jeunes se retrouvent autour de l’Apôtre Léopold MUTOMBO KALOMBO.
              De telles rencontres sont d’une nécessité absolue car « Jeune, si
              tu arrives à connaître Dieu et sa volonté pour toi, ta vie prendra
              désormais une nouvelle direction. » Dis l’Apôtre Léopold Mutombo.
            </Typography>
            <Typography sx={{ my: 2 }}>
              Tout ceci ne peut se passer que dans un cadre de partage de savoir
              avec celui qui a aussi fait l’expérience de la jeunesse et qui, à
              son tour, communique aux autres jeunes la pensée de Dieu pour les
              aider à prendre une bonne direction afin de vivre leur véritable
              destinée ; Telle est la raison d’être de la Reco-jeune, qui est la
              matérialisation du programme de YHWH Adonaï pour la jeunesse.
            </Typography>
            <Typography sx={{ my: 2 }}>
              Pendant la Reco-jeune, l’Apôtre Léopold Mutombo, visionnaire de ce
              colloque, qui de conscience patriarcale a à cœur de mettre à la
              disposition de ses allocuteurs des connaissances, des expériences
              variées selon Dieu et ce, sans détours.
            </Typography>
            <Typography sx={{ my: 2 }}>
              Par ailleurs, la junte juvénile fait également face à un libre
              échange, question de la situer et de l’orienter par rapport à la
              mue de la civilisation sous divers domaines de la vie quotidienne,
              par le biais de débats en atelier autour des thématiques axées sur
              la culture, l’environnement, la technologie, le développement, le
              management, la croissance spirituelle, le social, le sacerdoce, la
              vie professionnelle, la santé, etc.
            </Typography>
            <Typography sx={{ my: 2 }}>
              Ainsi, la RECO-JEUNE a enregistrée plusieurs grandes éditions
              2015, 2016, 2018, 2023 au sein du grand temple du Ministère Amen
              sur la 8ème Rue à Limete-Industriel.
            </Typography>
            <Typography sx={{ my: 2 }}>
              Au terme de ces rencontres, la Réco-Jeune Donne aux Jeunes
              l'information et les outils capables de se lancer dans son
              développement intégral. Étant enseigné et oint, chaque jeune en
              sort suffisamment équipé et prêt pour relever les grands défis de
              la vie. Car La jeunesse est l'avenir de demain. Elle est surtout
              zélée pour travailler.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <OurShop />
      </Container>
    </div>
  );
};

export default Jeunes;
