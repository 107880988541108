import {
  alpha,
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import SearchBar from "../includes/SearchBar";

const HomeBanner = () => {
  const theme = useTheme();
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Grid
      container
      spacing={2}
      sx={{
        p: { md: 8, xs: 2 },
        color: "white",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: `linear-gradient(
        to bottom,
         ${alpha(theme.palette.primary.main, 0.3)},
         ${alpha(theme.palette.primary.main, 0.4)}
      ), url('/images/banner-intro.JPG')`,
        height: window.innerHeight,
      }}
      direction={"row"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Container>
        <Typography
          variant={"h3"}
          sx={{ mt: 8, fontSize: { xs: 40 }, textTransform: "uppercase" }}
        >
          Le Monde entier est notre champ missionnaire.
        </Typography>
        <Typography>
          {" "}
          Bienvenue au site officiel du Ministère de l’Apotre Léopold MUTOMBO
          KALOMBO.
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Button component="a" href="/emi" variant="contained">
            En savoir plus
          </Button>
          <Button
            component="a"
            href="/a-propos"
            sx={{ mx: 2, color: "white", borderColor: "white" }}
            variant="outlined"
          >
            A propos
          </Button>
        </Box>
      </Container>
    </Grid>
  );
};

export default HomeBanner;
