import { Avatar, Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Breadcrumb from "../../components/includes/Breadcrumb";
import OurShop from "../../components/includes/Shop";
import HomeContact from "../../components/home/Contact";

const About = () => {
  return (
    <div>
      <Breadcrumb title={"A propos"} />
      <Container sx={{ my: 5 }}>
        <Grid container spacing={2}>
          <Grid
            container
            direction={"row"}
            justifyContent={"center"}
            item
            xs={12}
          >
            <Box>
              <Avatar
                src="/images/photo1.jpeg"
                sx={{ width: 200, height: 200 }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Container>
              <Typography sx={{ my: 2 }} variant="h3">
                Qui est l’Apôtre Léopold Mutombo Kalombo ?
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                L’Apôtre Léopold MUTOMBO est une bénédiction pour le Corps de
                Christ. Un dispensateur pour l’église de ce temps, un homme que
                Dieu a suscité et oint de manière très spéciale dans cette
                génération pour influencer des ministres de l’évangile, amener
                les chrétiens à élever leurs spiritualités en Christ et préparer
                l’Église du Seigneur à l’enlèvement.
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                Après avoir donné sa vie à Jésus-Christ le 5 avril 1974 ,
                l’Apôtre Léopold MUTOMBO KALOMBO, Fils de M’Zee Alexandre AIDINI
                ABALA de l'église locale FEPACO Nzambe Malamu, a reçu l’appel du
                Seigneur par la révélation de Jésus-Christ qui lui est apparu en
                1976 l’appelant au Ministère. Après sa formation biblique, il
                fut mis à part pour Dieu le 22 Octobre 1980.
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                Époux et père de famille, L’Apôtre Léopold Mutombo est Marié à
                Mme Mamie Mutombo et est père de deux enfants Siméon MUTOMBO et
                Jisca MUTOMBO.
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                Il est fondateur du groupe Amen Télévision, une grande Chaîne de
                Télévision Chrétienne, qui couvre localement près de 20 millions
                des téléspectateurs et des milliers en Afrique, en Asie, en
                Amérique, en Europe... sur 4 satellites (Eutelsat E16 et E7,
                Rascom Ku band et C band), en streaming et sur les réseaux
                sociaux.
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                Un intellectuel convaincant, conférencier, homme de lettres et
                Politologue, l’apôtre Léopold Mutombo Kalombo a influencé des
                milliers de gens par ses conférences prophétiques sur de grands
                thèmes tels que : La marque de la bête 666 ; <br />
                Le nouvel ordre mondial ;<br />
                Les enjeux prophétiques du proche et moyen orient ;<br />
                L’union Européenne et son rôle prophétique ;<br />
                La prophétie d’Israël, de l’Église et des Nations ;<br />
                Le retour du Seigneur Jésus-Christ ;<br />
                Ses conférences édifient et bénissent des milliers de gens et
                les préparent pour l’enlèvement de l’église.
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                Auteur de plusieurs ouvrages dont les plus connus sont : <br />
                - Le Tabernacle ; <br />
                - L’enlèvement de l’Église et le retour du Seigneur ; <br />
                - La destruction des Autels ;<br />
                - Comment reconnaître un vrai apôtre ; <br />
                - L’Héritage du 3ème Fils d’Abraham.
                <br />
              </Typography>
            </Container>
          </Grid>
        </Grid>
        <Grid container sx={{ my: 2 }} spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography>
              <img src="/images/about-1.JPG" alt="about1" width={"100%"} />
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container>
              <Typography textAlign={"justify"}>
                L’apôtre Léopold Mutombo est fondateur et représentant légal de
                la communauté nommée MINISTÈRE AMEN, une grande église locale,
                une œuvre évangélique avec des extensions en Europe, en Amérique
                Latine, en Afrique, dans les provinces de la République
                Démocratique du Congo ainsi que dans sa capitale, Kinshasa, où
                se situe son siège central. Les cultes au grand temple du
                Ministère Amen ne sont jamais ordinaires car le peuple de Dieu
                passe des puissants moments teintés du surnaturel comme jamais
                vécus auparavant dans l’histoire du réveil en République
                Démocratique du Congo.
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                Grand enseignant de la parole, solidement ancré dans la saine
                doctrine, sa philosophie sur l’église est d’un niveau très
                élevé. Par ailleurs, il est fondateur de plusieurs instituts
                bibliques comme : <br />
                -l’École de formation des disciples ; <br />
                -l’École de Tyranus ;<br />
                -l’E.B.S. (École Biblique de Samuel); <br />
                - la Pastorale Nationale (PANACO)
                <br />
                -l’École du Ministère Internationale;
                <br />
              </Typography>
            </Container>
          </Grid>
          <Container>
            <Typography sx={{ my: 2 }} textAlign={"justify"}>
              Il draine derrière lui plus de quatre décennies de vie
              ministérielle au service du maître de la vigne, Jésus-Christ,
              accordant une grande importance au salut des âmes par
              l’évangélisation des nations ! Doté d'une intelligence
              surnaturelle, le patriarche enseigne profondément avec exactitude
              la saine doctrine, afin d'inculquer à l'Église du Seigneur, la
              culture sacerdotale qui fait cruellement défaut aux chrétiens et à
              l'Église du temps de la fin. Son fardeau pour l’Église l'a
              toujours poussé à créer des écoles, cadre par excellence pour
              dispenser les enseignements sacerdotaux, ministériels et «
              onctionnels » afin que les serviteurs soient équipés et prêts à
              relever les défis de ce temps.
            </Typography>
          </Container>
        </Grid>
        <Grid container sx={{ my: 2 }} spacing={2} direction={"row-reverse"}>
          <Grid item xs={12} md={6}>
            <Typography>
              <img src="/images/about-2.JPG" alt="about2" width={"100%"} />
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container>
              <Typography textAlign={"justify"}>
                Il est l’initiateur de :<br />
                l’Action Réveil Moisson (AREM) qui a réunie des pasteurs venus
                de toutes les régions du Zaïre au tour de la Parole au Palais du
                Peuple de Kinshasa en 1995, de la Pastorale Nationale Congolaise
                (PANACO ) et de la grande École du Ministère Internationale
                (EMI), qui attirent des serviteurs, des servantes, des hommes de
                Dieu de partout dans le monde(de tous les continents) pour venir
                écouter cette « ENCYCLOPEDIE » évangélique, « UNE ECOLE VIVANTE
                » appelé l’Apôtre Léopold Mutombo non seulement pour être formé,
                mais surtout pour être « IMPACTE » de son Onction.
                <br />
                L’Apôtre Léopold Mutombo est un homme de masses ! Que des foules
                à chacune de ses réunions publiques ! Avec plus de 63 croisades
                à son actif, ses croisades attirent depuis 1986 d’immenses
                foules. Et le Seigneur Jésus-Christ accompagne ses croisades par
                des signes, des prodiges et des guérisons effroyables: tumeur,
                cancer et sida disparaissent ; des paralytiques marchent, des
                aveugles voient et même des enfants nés sans sexe recouvrent
                miraculeusement leurs sexes ; des morts ressuscitent ; des
                poissons, des lézards, des serpents ; des cafards, des souris,
                des objets métalliques sortent des corps, etc.
              </Typography>
              <Typography sx={{ my: 2 }} textAlign={"justify"}>
                De nombreuses croisades avec des signes, des prodiges et des
                miracles. Les réunions publiques comprennent
                <br /> : - Le Doigt de Dieu
                <br />
                - Le Ciel Ouvert
                <br /> - La Destruction des Autels
                <br /> - La Visitation Divine <br />- La Rencotre « Bizaleli Ya
                Mwasi » (Grande réunion des femmes de toutes les croyances){" "}
                <br />- La Réco-jeune
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </Container>
      <Box sx={{ backgroundColor: "whitesmoke", py: 5 }}>
        <Container>
          <OurShop />
        </Container>
      </Box>
      <HomeContact />
    </div>
  );
};

export default About;
