import React from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Breadcrumb from "../../components/includes/Breadcrumb";
import OurShop from "../../components/includes/Shop";

const agendaItems = [
  { date: "22 Juin 2024", event: "Seminaire 1", image: "/images/about-1.JPG" },
  {
    date: "27 Juillet 2024",
    event: "Seminaire 2",
    image: "/images/about-1.JPG",
  },
  { date: "30 Août 2024", event: "Seminaire 3", image: "/images/about-1.JPG" },
  {
    date: "28 Septembre 2024",
    event: "Seminaire  4",
    image: "/images/about-1.JPG",
  },
  { date: "14 Nov 2024", event: "Seminaire 5", image: "/images/about-1.JPG" },
];

function Agenda() {
  return (
    <Box>
      <Breadcrumb title={"Agenda"} />
      <Container sx={{ py: 5 }}>
        {agendaItems.map((item, index) => (
          <Grid
            direction={index % 2 == 0 ? "row" : "row-reverse"}
            container
            spacing={2}
          >
            <Grid item xs={12} md={6}>
              <Container sx={{ py: 5 }}>
                <Typography textAlign={"center"}>
                  <img src={item.image} width={"100%"} />
                </Typography>
              </Container>
            </Grid>
            <Grid
              container
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              item
              xs={12}
              md={6}
            >
              <Container>
                <Typography variant="h4">{item.event}</Typography>
                <Typography color={"secondary"} sx={{ my: 2 }}>
                  Date: {item.date}
                </Typography>
                <Typography>
                  Lieu: Lorem ipsum dolor sit amet, consectetur adipisicing
                  elit. Beatae doloribus earum vero, quam obcaecati facilis
                  recusandae eos ducimus tenetur.
                </Typography>
                <Typography sx={{ my: 2 }}>
                  <Button variant="contained" fullWidth>
                    S'enregistrer
                  </Button>
                </Typography>
              </Container>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        ))}
      </Container>
      <Container>
        <OurShop />
      </Container>
    </Box>
  );
}

export default Agenda;
