import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../components/includes/Breadcrumb";
import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { books } from "../../data";

const ShopDetails = () => {
  const params = useParams();
  const data = books.find((book) => book.id === params.ID);
  const [quantity, setQuantity] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [paiement, setPaiement] = useState(null);
  const [loading, setLoading] = useState(false);
  const apiUserName = "e8e7166b1e6842c99527e0a7e76540f5";

  const maxicashEndPoint =
    "https://webapi-test.maxicashapp.com/Integration/PayNowSync";
  const body = {
    RequestData: {
      Amount: (data.price * quantity).toString(),
      Reference: "paiementDeLivre",
      Telephone: "243" + phoneNumber,
    },
    MerchandID: "cdeecde1-5549-4408-a8b9-0a8ef3791f12",
    MerchandPassword: "1ad4bb909f384c1c96884af4533cbae7",
    PayType: paiement,
    CurrencyCode: "USD",
  };

  const handlePay = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(maxicashEndPoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      const data = await response.json();
      console.log("Success:", data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Breadcrumb title={"Shop"} />
      <Container sx={{ py: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography textAlign={"center"}>
              <img src={data && data.image} width={"50%"} alt="book" />
            </Typography>
          </Grid>
          <Grid
            container
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            item
            xs={12}
            md={6}
          >
            <Box>
              <Typography sx={{ my: 2 }} variant="h4">
                {data && data.title}
              </Typography>

              <form onSubmit={handlePay}>
                <Grid container sx={{ my: 2 }} spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Quantité"
                      name="quantity"
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Type de paiement"
                      name="paiement"
                      value={paiement}
                      select
                      onChange={(e) => setPaiement(e.target.value)}
                    >
                      <MenuItem value={1}>Airtel money</MenuItem>
                      <MenuItem value={2}>M-pesa</MenuItem>
                      <MenuItem value={3}>Orange money</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="Nom"
                      label="Nom complet"
                      name="name"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="81xxxxxxxxx"
                      label="Téléphone"
                      name="phoneNumber"
                      value={phoneNumber}
                      helperText="Commencez sans 0 (exemple: 81xxxxxxxx)"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Typography>Total: {quantity * data.price} USD</Typography>
                <Button
                  type="submit"
                  sx={{ my: 2 }}
                  fullWidth
                  variant="contained"
                >
                  {loading ? "paiement...." : "Acheter"}
                </Button>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ShopDetails;
