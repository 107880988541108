import React from "react";
import Breadcrumb from "../../components/includes/Breadcrumb";
import { useParams } from "react-router-dom";
import { oeuvres } from "../../data";
import { Container, Typography } from "@mui/material";
import OurShop from "../../components/includes/Shop";

const BlogDetails = () => {
  const params = useParams();
  const data = oeuvres.find((oeuvre) => oeuvre.id === params.articleID);

  return (
    <div>
      <Breadcrumb title={data.title} image={data.image} />
      <Container>
        <Typography sx={{ my: 5 }}>{data.content}</Typography>
      </Container>
      <Container>
        <OurShop />
      </Container>
    </div>
  );
};

export default BlogDetails;
